<template>
  <b-container
    v-if="project"
    class="content mb-5 ml-3"
  >
    <b-row class="mt-4">
      <b-col
        lg="12"
        xl="7"
        class="pr-2"
      >
        <BusinessCard :business="business" />
      </b-col>
      <b-col
        lg="12"
        xl="5"
        class="pl-2"
      >
        <ProjectSummary :project="project" />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <b-card
          no-body
          class="bottom-profiles"
        >
          <b-tabs
            class="tabs-headers"
            card
            no-fade
          >
            <TabOverview :project="project" />
            <!-- <b-tab title="Project proposal">
              <b-row>
                <b-col class="h7 mt-2 mb-3">
                  Project Fee Proposal
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-col class="mt-2 p-0">
                    <label class="data-field-heading">
                      <i class="ri-money-euro-box-line ri-xl pr-1" />
                      Project cost
                    </label>
                  </b-col>
                  <b-col class="pl-0">
                    <span>
                      <b-badge class="new-badge-dsg">
                        Fixed fee with pre-payment     €6000
                      </b-badge>
                      <b-badge class="new-badge-dsg">
                        Hourly + bonus per hire    €60-90
                      </b-badge>
                      <b-badge class="new-badge-dsg">
                        Open to other compensation models
                      </b-badge>
                    </span>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="h7 mt-5 mb-3">
                  Cover Letter
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="description">
                    Pamela née Beesly is a fictional character on the U.S. television sitcom The Office, played by Jenna Fischer. Her counterpart in the original UK series of The Office is Dawn Tinsley. Her character is initially the receptionist at the paper distribution company Dunder Mifflin, before becoming a saleswoman and eventually office administrator until she left in the series finale. Her character is shy, growing assertive but amiable, and artistically inclined, and shares romantic interest with Jim Halpert.
                    Pamela née Beesly is a fictional character on the U.S. television sitcom The Office, played by Jenna Fischer. Her counterpart in the original UK series of The Office is Dawn Tinsley. Her character is initially the receptionist at the paper distribution company Dunder Mifflin, before becoming a saleswoman and eventually office administrator until she left in the series finale. Her character is shy, growing assertive but amiable, and artistically inclined, and shares romantic interest with Jim Halpert.
                  </p>
                </b-col>
              </b-row>
            </b-tab> -->
            <!-- <b-tab title="References">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col class="text-center mt-6">
                      <img
                        src="/images/no-references.svg"
                        alt="no-references"
                      >
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-center mt-2">
                      <label class="data-field-heading">
                        Looks like you have no references yet
                      </label>
                    </b-col>
                  </b-row>
                  <b-row class="mb-6 ml-6 mr-6">
                    <b-col>
                      <p class="description text-center">
                        Postive client feedback  significantly strenghtens your chances for standing out in a competitive crowd!
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-tab> -->
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <RecruiterRequirements :project="project" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from '@/helpers/cleanAxios'
import BusinessCard from '@/components/project/BusinessCard'
import ProjectSummary from '@/components/project/ProjectSummary'
import TabOverview from '@/components/project/TabOverview'
import RecruiterRequirements from '@/components/project/RecruiterRequirements'

export default {
  name: 'MatchSingle',
  components: {
    BusinessCard,
    ProjectSummary,
    TabOverview,
    RecruiterRequirements,
  },
  data () {
    return {
      project: null,
      business: null,
    }
  },
  created () {
    axios.get(`/v2/matches/${this.$route.params.id}`).then(({ data }) => {
      this.$store.commit('agency/SET_MATCH', data)
      this.project = data.project
      this.business = data.project.business
    })
  },
  beforeDestroy () {
    this.$store.commit('agency/SET_MATCH', null)
  },
}
</script>

<style lang="scss" scoped>
.content {
  .bottom-profiles {
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border: none;
    border-radius: 12px;
    .h7 {
      font-size: 18px;
    }
    .description {
      font-weight: 300;
      line-height: 24px;
    }
    .li-text {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
  label {
    vertical-align: middle;
  }
}
</style>
